import React from 'react';
import {TextField} from "@mui/material";

const ChatGlobalSearch = () => {
    return (
        <TextField placeholder={"Search here..."} size={"small"} fullWidth/>
    );
};

export default ChatGlobalSearch;
