import React from 'react';
import SimpleBackdrop from "./SimpleBackdrop";

const Backdrops = () => {
    return (
      <SimpleBackdrop/>
    );
};

export default Backdrops;
